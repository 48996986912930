import functions from '../functions';
import { list } from './list';
import pagination from '../components/pagination';
import contactRequest from '../contactRequest';
import lozad from 'lozad'

$(() => {
    listFeatured.init();
});

const listFeatured = {
    _defaults: {
        _page: 1,
        perPage: 12,
        sortBy: '',
        language: functions.getLang(),
        template: 'featured',
        transportationType: null,
        paginate: 1,
        partner: null,
        exclude: null,
    },
    defaults: {},
    params: {},
    $listItemsAll: null,
    $listItems: null,
    $filter: null,
    filter: null,
    init() {

        this.$listItemsAll = $('.list-featured');
        this.$listItems = this.$listItemsAll.eq(0);

        if(! this.$listItems[0]) {
            return;
        }

         const observerVillas = lozad('.list-featured', {
                rootMargin: '200px 200px',
                loaded: el => {
                    let elem = this.$listItems = $(el);
                    // if ( !this.$listItems.attr('data-loaded') ) {
                        let params = this.setDefaults(elem);
                        params = this.getParams(params);
                        this.find(params, elem);
                    // }
                   
                    $(el).triggerHandler('lozadLoaded');
                }
            });
        observerVillas.observe();

        // always init with first "list"
        // if in tabs mode first list found is in first and active tab
        // if in normal mode first list is only list
        // this.setDefaults();
        // this.getParams();
        // this.find(this.params);

        // this.$filter = $('.search-filter');
        // this.$filter.on('change', e => {
        //     const $currentTarget = $(e.currentTarget);
        //     const formData = functions.getFormData($currentTarget);
        //     const target = $(e.target)[0];
        //     this.params._page = this.defaults._page;

        //     if(target && target.name == 'transportationType') {

        //         $('[name="transportationType"]').each((index, item) => {
        //             if(target.id != item.id) {
        //                 $(item).prop('checked', false);
        //             }
        //         });

        //         formData.transportationType = target.checked ? target.value : this.defaults.transportationType;
        //     }

        //     this.getParams();
        //     this.params = $.extend(this.params, this.defaults, formData);
        //     functions.setUrlData(this.params, true, true);
        // });

        // $(window).on('hashchange', e => {
        //     this.getParams();
        //     this.stopChangeEvents = true;

        //     functions.scrollToElement(this.$listItems, 100);
        //     this.find(this.params);
        //     this.stopChangeEvents = false;
        // });

        // tabs mode
        // if ( this.$listItems.attr('data-tabs') && this.$listItemsAll.length > 1 ) {
        //     // safe way to get tabs. not to rely on html structure
        //     const $tabs = $($.map(this.$listItemsAll, listItems => {
        //         return `.nav-link[data-bs-target="#${$(listItems).closest('.tab-pane').attr('id')}"]`;
        //     }).join(', '));
        //     $tabs.on('shown.bs.tab', e => {
        //         this.$listItems = this.$listItemsAll.eq($tabs.index(e.target));
        //         if ( !this.$listItems.attr('data-loaded') ) {
        //             this.setDefaults();
        //             this.getParams();
        //             this.find(this.params);
        //         }
        //     });
        // }
        // else if (this.$listItemsAll.length > 1)
        // {
        //     let currItem = null;
        //     this.$listItemsAll.each((index, elem) => {
        //         if (index == 0) return true;
        //         currItem = this.$listItemsAll.eq(index);
        //         if ( !this.$listItems.attr('data-loaded') ) {
        //             this.setDefaults(currItem);
        //             this.getParams();
        //             this.find(this.params, currItem);
        //         }
        //     })

            // const observerVillas = lozad('.list-featured', {
            //     rootMargin: '200px 200px',
            //     loaded: el => {
            //         let villasSlider = [];
            //         $(el).find('[id^=villa-card-slider]').each((i, elem) => {
            //             const length = $(elem).children('.keen-slider__slide').length;
            //             villasSlider[i] = new Slider('#' + $(elem).attr('id'), {
            //                 rubberband: false,
            //                 loop: true,
            //                 arrows: true,
            //                 drag: true,
            //                 controls: true,
            //                 slides: {
            //                     perView: 1
            //                 },
            //                 spacing: 0,
            //                 duration: 1000,
            //                 autoplay: 0,
            //                 pauseOnHover: true,
            //                 pager: false,   
            //                 autoLoadSlides: 2,
                                
            //             });
            //         });
            //         $(el).triggerHandler('lozadLoaded');
            //     }
            // });
            // observerVillas.observe();
        // }

    },
    setDefaults(currItem = null) {
        let currentList = currItem || this.$listItems;
        let params = {};
        params.dynamicPackage = currentList.attr('data-dynamicpackage');
        params.countryId = currentList.attr('data-countryid');
        params.destination = currentList.attr('data-destination');
        params.objectGroupId = currentList.attr('data-objectgroupid');
        params.objectTypeId = currentList.attr('data-objecttypeid');
        params.tagCategoryId = currentList.attr('data-tagcategoryid');
        params.tagCategoryIds = currentList.attr('data-tagcategoryids');
        params.catalogueId = currentList.attr('data-catalogueId');
        params.transportationType = currentList.attr('data-transportationtype') || this._defaults.transportationType;
        params.template = currentList.attr('data-template') || this._defaults.template;
        params.perPage = currentList.attr('data-perPage') || this._defaults.perPage;
        params.sortBy = currentList.attr('data-sortBy') || this._defaults.sortBy;
        params.paginate = currentList.attr('data-paginate') || this._defaults.paginate;
        params.partner = currentList.attr('data-partner') || this._defaults.partner;
        params.exclude = currentList.attr('data-exclude') || this._defaults.exclude;
        params.hasArrival = currentList.attr('data-hasarrival') || this._defaults.exclude;
        return params;
    },
    getParams(params) {
        return list.getParams(this.params, params);
    },
    find(params, currItem = null) {
        // this.setLoadingMode(currItem);
        this.params = params;

        $.get('/services/list/', params).then((response) => {
            this.findDone(response, currItem);
            // LOZAD
            window.observer.observe();

            $('.departure').on('click', e => {
                e.preventDefault(currItem);

                const $target = $(e.currentTarget);
                let href = $target.attr('data-href');
                href = href.split('#')[0];

                const dateFrom = $target.attr('data-datefrom');
                const dateTo = $target.attr('data-dateto');

                href += '#dateFrom=' + dateFrom + '&dateTo=' + dateTo;
                //location = href; 
                window.open(href, '_blank');
            });

        });
    },
    findDone(response, currItem = null) {
        response = JSON.parse(response);          

        this.unsetLoadingMode();

        this.total = response.total;
        let currentList = currItem || this.$listItems;
        currentList.html(response.html).attr('data-loaded', 1);

        functions.markFavorites()
        // if (this.total != 0) {

        //     if($('.paginate')[0] && +this.defaults.paginate) {
        //         $('.paginate').html(pagination({
        //             currentPage: this.params._page,
        //             total: this.total,
        //             perPage: this.params.perPage,
        //         }));
        //     }
        // }

        //listResults.getResults(this.total, this.params._page, this.params.perPage);

        this.initComponents();

    },
    setLoadingMode(currItem = null) {
        let item = currItem ||this.$listItems;
        if(! this.loading) {
            this.loading = true;

            if ( ! $('.spinner').length) {
                $('<div class="spinner d-none my-5 py-5"><div class="spinner-border text-secondary" role="status" style="width: 4rem; height: 4rem;"><span class="sr-only">Loading...</span></div></div>').insertBefore('.list-featured');
            }

            $('.spinner').removeClass('d-none');
            $('.spinner').addClass('d-flex justify-content-center');
            item.addClass('d-none');
            $('.paginate').addClass('d-none');
        }
    },
    unsetLoadingMode(currItem = null) {
        let item = currItem ||this.$listItems;
        if(this.loading) {
            this.loading = false;

            $('.spinner').removeClass('d-flex justify-content-center');
            $('.spinner').addClass('d-none');
            item.removeClass('d-none');
            $('.paginate').removeClass('d-none');

            //functions.scrollToElement(this.$listItems, 100);
        }
    },
    initComponents() {

        // register paginate        
        $('[data-page]').on('click', e => {
            e.preventDefault();
            this.paginate(+$(e.currentTarget).attr('data-page'));
        });

        contactRequest.contactRequest.init();

    },
    paginate(page) {
        if (page != this.params._page) {
            this.params._page = page;
        }

        functions.setUrlData(this.params, true);
    },
};